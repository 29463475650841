<template>
  <div class="ak-text container-layout-custom" style="max-width: 1980px;">
    <div class="q-pa-xl full-width">
      <div class="row q-mb-xl justify-between">
        <div class="text-h4">Unlock Quizzes</div>
      </div>

      <div class="q-mt-lg">
        <div class="row full-width items-end">
          <div v-if="usersList.length > 0" class="col-md-5">
            <filter-select clearable emit-value map-options class="full-width" filter v-bind:selection.sync="selectedUser" :options="usersList" label="User" />
          </div>
          <div v-else class="col-md-5">
            <div class="text-subtitle1">Currently no quizzes to unlock</div>
          </div>
        </div>
        <div class="row full-width q-ma-md">
          <template v-if="selectedUser != null && $q.loading.isActive === false">
            <q-list separator class="full-width">
              <q-item v-if="lockedModules.length === 0">
                <q-item-section></q-item-section>
              </q-item>
              <module-list-item
                v-for="(module, i) in lockedModules"
                :module="module"
                :key="module.uid"
                :linkable="false"
                >
                  <template v-slot:topRightAction>
                    <q-btn flat label="Unlock" @click="unlockQuiz(module.uid, i)"/>
                  </template>
              </module-list-item>
            </q-list>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModuleListItem from 'components/ModuleListItem'
import FilterSelect from 'components/filterSelect'

export default {
  components: { ModuleListItem, FilterSelect },
  data () {
    return {
      usersList: [],
      allLockedQuizzes: [],
      selectedUser: null,
      lockedQuizzes: [],
      lockedModules: []
    }
  },
  watch: {
    selectedUser: function (userUid) {
      if (userUid === null) return
      this.$q.loading.show()
      this.lockedQuizzes = []
      this.lockedModules = []

      this.lockedQuizzes = this.allLockedQuizzes.filter(quiz => quiz.user_uid === userUid)
      if (this.lockedQuizzes.length > 0) this.getAlgoliaInfo()
      this.$q.loading.hide()
    }
  },
  methods: {
    unlockQuiz (moduleUid, moduleIndex) {
      this.$q.loading.show()
      let quizUids = this.lockedQuizzes.map(locked => {
        if (locked.module_uid === moduleUid) {
          return locked.quiz_uid
        }
      })

      this.$axios.post(this.$consts.UNLOCK_QUIZ_URL, {
        'user_uid': this.selectedUser,
        'quiz_uids': quizUids
      }).then((response) => {
        this.$q.loading.hide()
        this.lockedQuizzes = response
        this.lockedModules.splice(moduleIndex, 1)

        this.$successMsg('Quiz has been unlocked.')
      }).catch(e => {
        this.$q.loading.hide()
        this.failureMsg()
      })
    },
    async getAlgoliaInfo () {
      this.$q.loading.show()
      let moduleIds = this.lockedQuizzes.map(quiz => `uid:${quiz.module_uid}`)
      this.$moduleSearch.clearCache()
      await this.$moduleSearch.search([
        {
          indexName: this.$consts.MODULES_INDEX,
          query: '',
          params: {
            hitsPerPage: 50,
            filters: moduleIds.join(' OR ')
          }
        }
      ], (err, content) => {
        if (err) throw err
        this.lockedModules = content.results['0'].hits
      })
    }
  },
  created () {
    this.$loading(true, 'Fetching Locked Quizzes')
    this.$axios.get(this.$consts.GET_LOCKED_QUIZZES_URL).then((response) => {
      this.usersList = response.users
      this.allLockedQuizzes = response.quizzes
      this.$loading(false)
    })
  }
}
</script>

<style>

</style>
